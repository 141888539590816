import React, { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import some from "lodash/some";

import { Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import {
  useAnalytics,
  useAuth,
  useGoogleTracking,
  useLocalStorage,
  useOperatorSlug,
  useScreenSize,
  useScrollToTop,
  useSnackbar,
} from "globals/hooks";
import { RoundTripVariant, TripCategory } from "types";
import { useEstimatedDurationOnInfoPage } from "./context/hooks";
import { InfoStepState } from "./context/InfoStepFormProvider";
import { useCreateRequestContext } from "pages/new/context/useCreateRequestContext";
import { CreateRequestReduxActionTypes } from "pages/new/context/reducer/types";
import OrderTypeBlock from "./components/OrderTypeBlock";
import StatusStepper from "../components/StatusStepper";
import TripCategoryBlock from "./components/TripCategoryBlock";
import TripInfoBlock from "./components/TripInfoBlock";
import BookingContactBlock, {
  BookingContactData,
} from "../../../../components/auth/LoginDialog/components/BookingContactBlock";
import { UPDATE_CONTACT_MUTATION } from "../../../../globals/graphql";
import { getIsNetflixLogin } from "utils/auth/isNetflixLogin";
import { decodeGlobalId } from "../../../../utils/helpers";
import useLoadVehicleBRA from "./hooks/useLoadVehicleBRA";

type InfoPageProps = {
  isWhiteLabel?: boolean;
};

const InfoPage: React.FC<InfoPageProps> = ({ isWhiteLabel }) => {
  useScrollToTop({ scrollOnMount: true });
  const snackbar = useSnackbar();
  const [_, setSource] = useLocalStorage("source");
  const operatorSlug = useOperatorSlug();
  const history = useHistory();
  const { isTabletView, isMobileView } = useScreenSize();
  const { track } = useAnalytics();
  const { googleTrack } = useGoogleTracking();
  const { authStage, onLoginDialogOpen, loginData, setLoginData } = useAuth();
  const isNetflixLogin = getIsNetflixLogin();
  const [contactsModalOpen, setContactsModalOpen] = useState(false);
  const [isNextStep, setIsNextStep] = useState<boolean>(false);

  const { watch, handleSubmit } = useFormContext<InfoStepState>();
  const [tripCategory, returnTrip, trip] = watch([
    "trip.tripCategory",
    "returnTrip",
    "trip",
  ]);
  const [
    ,
    dispatch,
    { skipVehicleDefaultVehicle, skipVehicleSelectionEnabled },
  ] = useCreateRequestContext();

  const [queryVehicleBRA, isLoadingBRA] = useLoadVehicleBRA();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const platform = queryParams.get("source");

    if (platform) {
      setSource(platform);
    }
  }, [setSource]);

  const isDirectionsLoading = useEstimatedDurationOnInfoPage();

  const handleNextPage = useCallback(async () => {
    if (skipVehicleDefaultVehicle && skipVehicleSelectionEnabled) {
      // query for BRA
      const vehicleWithBRA = await queryVehicleBRA({
        vehicleId: skipVehicleDefaultVehicle.id,
        trip,
        returnTrip,
        tripCategory,
      });

      dispatch({
        type: CreateRequestReduxActionTypes.UpdateVehiclePage,
        payload: vehicleWithBRA,
      });

      history.push({
        ...history.location,
        pathname: `/${operatorSlug}/new/confirm`,
      });

      track(
        isWhiteLabel ? "whiteLabel_chooseVehicle" : "bookingTool_chooseVehicle",
        {
          type: vehicleWithBRA.baseRateAutomation
            ? "BRA"
            : vehicleWithBRA.settings?.pricelessBookingEnabled
            ? "Reservation Request"
            : "Quote Request",
        }
      );
    } else {
      history.push({
        ...history.location,
        pathname: isWhiteLabel
          ? `/${operatorSlug}/booking/vehicle`
          : `/${operatorSlug}/new/vehicle`,
      });
    }
  }, [
    dispatch,
    history,
    isWhiteLabel,
    operatorSlug,
    skipVehicleSelectionEnabled,
    track,
    skipVehicleDefaultVehicle,
    queryVehicleBRA,
    trip,
    returnTrip,
    tripCategory,
  ]);

  const handleContactsModalOpen = useCallback(() => {
    if (
      loginData &&
      authStage === "authenticated" &&
      (!loginData.firstName || !loginData.lastName)
    ) {
      setContactsModalOpen(true);
    } else if (isNextStep && authStage === "authenticated") {
      handleNextPage();
    }
  }, [authStage, loginData, isNextStep, handleNextPage]);

  useEffect(() => {
    googleTrack("moovs_page_view_info");
  }, [googleTrack]);

  useEffect(() => {
    handleContactsModalOpen();
  }, [authStage, loginData, handleContactsModalOpen]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get("moovs_source") === "widget") {
      track("bookingWidget_clicked");
    }
    track("bookingTool_requestInfoInitiated");
  }, [track]);

  const handleNextClick = (infoPageValues: InfoStepState) => {
    dispatch({
      type: CreateRequestReduxActionTypes.UpdateInfoPage,
      payload: infoPageValues,
    });

    if (isNetflixLogin && authStage !== "authenticated") {
      onLoginDialogOpen({
        isNetflixLogin,
        onVerifySuccess: () => setIsNextStep(true),
      });
    } else if (
      isNetflixLogin &&
      loginData &&
      (!loginData.firstName || !loginData.lastName)
    ) {
      setContactsModalOpen(true);
    } else {
      handleNextPage();
    }

    track(
      isWhiteLabel ? "whiteLabel_nextStep" : "bookingTool_nextStep",
      !isWhiteLabel && {
        tripType: infoPageValues.trip.tripCategory,
        orderType: infoPageValues.orderType.name,
        location: some(infoPageValues.trip.stops, { variant: "airport" })
          ? "airport"
          : "address",
      }
    );
  };

  const [updateContact] = useMutation(UPDATE_CONTACT_MUTATION, {
    onCompleted: () => {
      snackbar.success("Successfully updated contact!");
      setContactsModalOpen(false);
      if (isNextStep) {
        handleNextPage();
      }
    },
    onError: (error) => {
      snackbar.error(error.message || "Error updating passenger");
    },
  });

  const handleUpdateContact = (contactData: BookingContactData) => {
    updateContact({
      variables: {
        input: {
          id: loginData.contactId
            ? loginData.contactId
            : decodeGlobalId(loginData.id),
          firstName: contactData.bookingContact.firstName,
          lastName: contactData.bookingContact.lastName,
        },
      },
    });

    setLoginData({
      ...loginData,
      firstName: contactData.bookingContact.firstName,
      lastName: contactData.bookingContact.lastName,
    });
  };

  const handleCloseModal = () => {
    if (!isNetflixLogin) {
      setContactsModalOpen(false);
    }
  };

  return (
    <>
      <form
        id="info-page-form"
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();

          return handleSubmit(handleNextClick)(e);
        }}
      >
        <Box width="100%" mb={8}>
          <StatusStepper activeStep={0} hideLogin={isWhiteLabel} />
          <Box
            display="flex"
            flexDirection="column"
            width={isMobileView ? "90%" : isTabletView ? "70%" : "55%"}
            mx={isMobileView ? 2 : isTabletView ? 10 : 24}
          >
            <Box mt={3}>
              <TripCategoryBlock showAllTripCategories={isWhiteLabel} />
            </Box>

            <Box my={1}>
              <OrderTypeBlock showAllOrderTypes={isWhiteLabel} />
            </Box>

            {/* Solo trip / Outbound trip (only round trips) */}
            <TripInfoBlock
              roundTripVariant={
                tripCategory === TripCategory.RoundTrip
                  ? RoundTripVariant.Outbound
                  : undefined
              }
            />

            {/* Return trip (only round trips) */}
            {tripCategory === TripCategory.RoundTrip && returnTrip && (
              <Box mt={4}>
                <TripInfoBlock roundTripVariant={RoundTripVariant.Return} />
              </Box>
            )}

            {/* stepper buttons */}
            <Box mt={3} display="flex" justifyContent="flex-end">
              <LoadingButton
                variant="contained"
                color="primary"
                type="submit"
                form="info-page-form"
                disabled={isDirectionsLoading}
                loading={isLoadingBRA}
              >
                Next Step
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </form>
      <BookingContactBlock
        onSubmit={handleUpdateContact}
        isOpen={contactsModalOpen}
        onClose={handleCloseModal}
        isShowCloseButton={!isNetflixLogin}
        initialData={{
          firstName: loginData?.firstName,
          lastName: loginData?.lastName,
        }}
      />
    </>
  );
};

export default InfoPage;
